import { FormikHelpers } from 'formik';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { Icon, Segment } from 'semantic-ui-react';
import { useLightbox } from 'simple-react-lightbox';
import styled from 'styled-components';
import HiddenImage from '../../../app/common/image/HiddenImage';
import { useStore } from '../../../app/stores/store';
import AttachmentEditForm from '../card-attachments/AttachmentEditForm';

//#region Styling
const StyledSegment = styled(({ hasView, ...rest }) => <Segment {...rest}></Segment>)<{ hasView: boolean }>`
    padding: 6px !important;
    margin: 6px 0 !important;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    box-shadow:none;
    transition: box-shadow .3s;
    ${p => (p.hasView ? `
        &:hover{
            margin-top: -1px;
            margin-left: -1px;
            box-shadow: 0 0 11px rgba(33,33,33,.2);
            cursor: pointer;
        }
    ` : '')};
    
`;
const ActionWrappers = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
`;

const StyledIcon = styled(Icon)`
    && {
        box-shadow: none !important;
        &:hover{
            background-color: #eee;
            cursor: pointer;
        }
    }
`;

//#endregion

const getName = (fileName) => {
    let lastIndex = fileName.lastIndexOf(".");
    return fileName.substring(0, lastIndex);
}

interface Props {
    fileName: string;
    fileId?: string;
    lightBoxIndex: number;
    onRemove?: (fileName: string) => void | undefined;
    isOpen?: boolean;
    attachmentId?: string;
    onDelete?: (attachmentId: string) => void | undefined;
}

const isImage = (fileName) => {
    return fileName.match(/.(jpg|jpeg|png)$/i);
}

const saveFile = (data, fileName) => {
    const a = document.createElement('a');
    document.body.appendChild(a);
    const url = window.URL.createObjectURL(data);
    a.href = url;
    a.download = fileName;
    a.click();
    setTimeout(() => {
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
    }, 0)
}

const CommentAttachment = ({ fileId,fileName, lightBoxIndex, onRemove, isOpen, attachmentId, onDelete }: Props) => {
    const { commentAttachmentStore, popOverStore } = useStore();
    const { getBlob, updateFileName } = commentAttachmentStore;
    const { openLightbox, closeLightbox } = useLightbox();

    const handleOnClickAttachment = (event: any) => {
        if (attachmentId) {
            if (event.target !== event.currentTarget) return;

            if (isImage(fileName)) {
                openLightbox(lightBoxIndex!)
            }

            else {
                getBlob(attachmentId).then(v => {
                    if (v != undefined) {
                        saveFile(v, fileName);
                    }
                });
            }
        }
    }

    const handleAttachmentUpdateSubmit = (values: { fileName: string },
        { resetForm, setSubmitting }: FormikHelpers<{ fileName: string }>) => {

        updateFileName(values.fileName + '.' + fileName.split('.').pop(), attachmentId!)
            .then(
                result => {
                    setSubmitting(false);
                    popOverStore.closePopover();
                },
                reason => {
                    console.log(reason)
                    setSubmitting(false);
                }
            );
    }

    const handleOnEdit = (event: any) => {
        event.stopPropagation();
        popOverStore.openPopover((
            <AttachmentEditForm
                placeholder='Enter filename'
                rows={2}
                label='File Name'
                isResizable={true}
                initialValue={getName(fileName)}
                handleSubmit={handleAttachmentUpdateSubmit}
                name='fileName'
            />), event.target);
    }

    return (
        <>
            <StyledSegment size='tiny' onClick={handleOnClickAttachment} hasView={attachmentId != undefined}>
                {fileName}
                {attachmentId ? (<HiddenImage attachmentId={attachmentId} />) : ''}
                {isOpen ?
                    (<ActionWrappers>
                        {attachmentId ? (<>
                            <StyledIcon name='edit outline' bordered onClick={handleOnEdit} />
                            <StyledIcon name='trash alternate' bordered onClick={() => onDelete!(attachmentId)} /></>)
                            :
                            (<StyledIcon name='close' bordered onClick={() => onRemove!(fileId!)} />)}
                    </ActionWrappers>)
                    : ''
                }
            </StyledSegment>
        </>
    );
}

export default observer(CommentAttachment);