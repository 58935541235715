import { CardChecklist } from "./cardChecklist";
import { CardComment } from "./cardComment";
import { CardAttachment } from "./cardAttachment";

export interface Card {
    id: string;
    title: string;
    description?: string;
    cardGroupId: string;
    createdUserId: string;
    comments: CardComment[];
    checklists: CardChecklist[];
    attachments: CardAttachment[];
}

export class CardFormValues {
    id?: string = undefined;
    title: string = '';
    description: string = '';
    cardGroupId: string = '';
    createdUserId: string = '';

    constructor(card?: Card) {
        if (card) {
            this.id = card.id;
            this.title = card.title;
            this.cardGroupId = card.cardGroupId;
            this.createdUserId = card.createdUserId;
            if (card.description)
                this.description = card.description;

        }
    }
}

